<!-- 旋转矩阵 -->
<template>
  <div id="dialog">
    <div class="border">
      <div @click="closeDialog" class="closeImg">
        <svg
          t="1634463638558"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1908"
          width="100%"
          height="100%"
        >
          <path
            d="M823.04 823.04A439.893333 439.893333 0 1 1 341.333333 106.666667a439.893333 439.893333 0 0 1 481.706667 716.373333zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m205.226667 306.346667a35.84 35.84 0 0 0-50.773334 0L512 460.8 357.546667 306.346667a35.84 35.84 0 0 0-51.2 50.773333L461.226667 512l-154.453334 154.88a35.84 35.84 0 1 0 51.2 50.773333L512 563.2l154.453333 154.88a35.84 35.84 0 1 0 51.2-50.773333L562.773333 512l154.453334-154.88a35.84 35.84 0 0 0 0-50.773333z"
            fill="#5E5C5C"
            p-id="1909"
          ></path>
        </svg>
      </div>
      <div class="wind-mat">
        <div class="wind-title">旋转矩阵</div>
        <div class="wind-z">
          <div style="font-size: 16px">
            旋转公式：中 {{ zi }} 保 {{ zi - 1 }}
          </div>
          <div style="font-size: 14px; margin-top: 10px">
            说明：世界上著名的彩票专家、澳大利亚数学家底特罗夫研究的，可以帮助您锁定喜爱的号码，提高中奖的机会。实现中{{
              zi
            }}保{{ zi - 1 }}(只要选定的号码中含有{{
              zi
            }}个正确的红号，结果肯定有一注含有{{
              zi - 1
            }}个正确的红号)，同时以极低的成本实现复试投注的效果。
          </div>
          <div style="font-size: 14px">
            注：旋转矩阵单独使用可保证其效果，否则无法保证。
          </div>
          <div class="wind-w" @click="add()">确定添加</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
//import api from '@/js/method'
export default {
  name: "wind-matrix",
  components: {},
  data() {
    // 数据源
    return {
      matrix: [],
    };
  },
  mounted() {
  
  },

  computed: {
    // sd() {
    //     return this.$store.state.sd;
    // },
   
  },

  // 函数体部分，js的主要逻辑控制
  methods: {
    //给父组件传参
    closeDialog() {
      this.$emit("close", false);
    },
    add() {
      this.$emit("func", this.zi);
      this.$emit("closeDialog", false);
    },
    
  },
  props: ["zi"],
  
};
</script>

<style scoped>
/* @import './swiper.css'; */
#dialog {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.closeImg {
  width: 30px;
  height: 30px;
  float: right;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  /* background-image: url(../../assets/close.png); */
  /* background-color:rgb(194, 192, 192); */
  background-size: cover;
  background-repeat: no-repeat;
}

.border {
  text-align: center;
  background-color: white;
  border-radius: 20px;
  width: 350px;
  height: 350px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wind-title {
  width: 100%;
  font-size: 16px;
  margin-top: 10px;
}
.wind-z {
  margin: 0 auto;
  margin-top: 15px;
  width: 90%;
  height: 280px;
  border: 1px solid rgb(221, 220, 220);
}
.wind-w {
  width: 30%;
  height: 28px;
  margin-top: 40px;
  background-color: red;
  color: aliceblue;
  display: inline-block;
  border-radius: 5px;
  line-height: 27px;
  font-weight: bold;
}
</style>